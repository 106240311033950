import { useMemo, useState } from 'react'
import { MenuItem, Typography } from '@material-ui/core'
import {
	InputField,
	InputMask,
	RadioGroup,
	CheckboxList,
	InputDate,
	InputTime,
	InputDateTime,
	MultipleSelect,
	InputSimpleDate,
} from 'components'
import { masks } from 'enum/fieldsMasks'
import { isEmpty } from 'lodash'
import { useFormContext } from 'react-hook-form'
import PhysicalExam from "../physical-exam";
import LabelField from './LabelField'


const CustomField = (props) => {
	const {
		id,
		label,
		type,
		readOnly,
		mask,
		options,
		default_value,
		defaultAnswers = {},
		text,
		tag,
		helper_text: helperText,
		isPreview,
		customer
	} = props
	const { control } = useFormContext()
	
	const findMask = (mask, attr = null) => {
		const maskValue = masks.find((el) => {
			return el.id === mask
		})
		if (maskValue) {
			switch (attr) {
				case 'beforeValueChange':
					return maskValue.beforeMaskedValueChange
				default:
					return maskValue.mask
			}
		} else return ''
	}

	const [height, setHeight] = useState(null)
	const [weight, setWeight] = useState(null)

	const handleLimit = (value, min, max) => {
		if (value != ',') value = parseFloat(value) ?? 1

		if (value > max) return max
		if (value < min) return min

		return value
	}

	const handleHeight = (e) => {
		const min = 1
		const max = 300

		const value = handleLimit(e.target.value, min, max)
		setHeight(value)
	}

	const handleWeight = (e) => {
		const min = 1
		const max = 700

		const value = handleLimit(e.target.value, min, max)
		setWeight(value)
	}

	const findValueById = (value, options, isMulti) => {
		if (!value) return null
		if (options.length == 0) return value
		try {
			if (!isMulti || typeof value == 'string') {
				return options.find(opt => opt.value == value)?.label || null
			} else {
				if (typeof value == 'object') value = Object.keys(value).map(val => value[val]);
				return value.map(val => options.find(opt => opt.value == val)?.label || null)
			}
		} catch (err) {
			console.log('Erro FindValueById: ', err)
			return null
		}
	}
	
	const fieldHasDefaultValues = (fieldName, id, isObject = false, options = [], multi = false) => {
		console.log('default values', fieldName, id, isObject, options, multi);
		if (isObject == 'imc' || isObject == 'imc_gestacional') return {
			weight: defaultAnswers['weight'] || defaultAnswers['pesoaltura']?.weight || defaultAnswers['pesoxaltura']?.weight,
			height: defaultAnswers['height'] || defaultAnswers['pesoaltura']?.height || defaultAnswers['pesoxaltura']?.height
		};
		let value = defaultAnswers ? defaultAnswers[fieldName] : null
		if (!value) value = defaultAnswers ? defaultAnswers[id] : null
		if (!value) value = findValueById(value, options, multi)
		if (!value) return null
		if (options.length === 0) return value
		if (multi) {
			try {
				if (typeof value === "object") value = Object.keys(defaultAnswers[tag]).map((key) => defaultAnswers[tag][key])
			} catch (err) {
				if (typeof value === "object") value = Object.keys(defaultAnswers[id]).map((key) => defaultAnswers[id][key])
			}
			const values = [];

			if (!Array.isArray(value)) return null;

			value.map(vl => {
				const option = options.find(opt => opt.label == vl || opt.value == vl)
				if (option) values.push(option.value)
			})
			console.log('values', options);
			return values
		} else {
			const option = options.find(opt => opt.label == value || opt.value == value)
			console.log('option', value, option, options);
			return option ? option.value : null
		}
	}

	const fieldMemoized = useMemo(() => {
		switch (type) {
			case 'label':
				return (
					<LabelField value={text || label} customer={customer} /> 
				)
			case 'text':
				if (!isEmpty(mask)) {
					return (
						<InputMask
							id={id}
							name={id}
							label={label}
							mask={findMask(mask)}
							beforeMaskedValueChange={findMask(mask, 'beforeValueChange')}
							helperText={helperText}
							defaultValue={fieldHasDefaultValues(tag, id, false) || default_value}
							variant='outlined'
							fullWidth
							InputProps={{
								readOnly,
							}}
						/>
					)
				}
				return (
					<InputField
						id={id}
						name={id}
						label={label}
						control={control}
						helperText={helperText}
						defaultValue={fieldHasDefaultValues(tag, id, false) || default_value}
						variant='outlined'
						fullWidth
						InputProps={{
						readOnly,
						}}
					/>
				)
			case 'textarea':
				return (
					<InputField
						id={id}
						name={id}
						label={label}
						fullWidth
						multiline={true}
						helperText={helperText}
						defaultValue={fieldHasDefaultValues(tag, id, false) || default_value}
						InputProps={{
							readOnly,
						}}
					/>
				)
			case 'number':
				if (!isEmpty(mask)) {
					return (
						<InputMask
							id={id}
							name={id}
							label={label}
							mask={findMask(mask)}
							beforeMaskedValueChange={findMask(mask, 'beforeValueChange')}
							helperText={helperText}
							defaultValue={fieldHasDefaultValues(tag, id, false) || default_value}
							variant='outlined'
							fullWidth
							InputProps={{
								readOnly,
							}}
						/>
					)
				}
				return (
					<InputField
						id={id}
						name={id}
						label={label}
						control={control}
						helperText={helperText}
						type="number"
						defaultValue={fieldHasDefaultValues(tag, id, false) || default_value}
						variant='outlined'
						fullWidth
						InputProps={{
						readOnly,
						}}
					/>
				)
			case 'imc':
				return (
					<>
						<InputMask
							id={`height`}
							name={`height`}
							label={'Qual sua altura (em cm)?'}
							mask={findMask('imc')}
							helperText={helperText}
							defaultValue={fieldHasDefaultValues(tag, id, 'imc')?.height || default_value}
							variant='outlined'
							fullWidth
							InputProps={{
								readOnly,
							}}
						/>
						<InputMask
							id={`weight`}
							name={`weight`}
							label={'Qual seu peso (em kg)?'}
							mask={findMask('imc')}
							helperText={helperText}
							defaultValue={fieldHasDefaultValues(tag, id, 'imc')?.weight || default_value}
							variant='outlined'
							fullWidth
							InputProps={{
								readOnly,
							}}
						/>
					</>
				)
			case 'imc_gestacional':
				return (
					<>
						<InputMask
							id={`height`}
							name={`height`}
							label={'Qual sua altura (em cm)?'}
							mask={findMask('imc')}
							helperText={helperText}
							defaultValue={fieldHasDefaultValues(tag, id, 'imc_gestacional')?.height || default_value}
							variant='outlined'
							fullWidth
							InputProps={{
								readOnly,
							}}
						/>
						<InputMask
							id={`weight`}
							name={`weight`}
							label={'Qual seu peso (em kg)?'}
							mask={findMask('imc')}
							helperText={helperText}
							defaultValue={fieldHasDefaultValues(tag, id, 'imc_gestacional')?.weight || default_value}
							variant='outlined'
							fullWidth
							InputProps={{
								readOnly,
							}}
						/>
					</>
				)
			case 'select':
				return (
					<InputField
						id={id}
						name={id}
						label={label}
						helperText={helperText}
						defaultValue={fieldHasDefaultValues(tag, id, false, options) || default_value}
						fullWidth
						select
						InputProps={{
							readOnly,
						}}
					>
						{!isEmpty(options) ? (
							options.map((opt) => (
								<MenuItem key={opt.label} value={opt.value}>
									{opt.label}
								</MenuItem>
							))
						) : (
							<MenuItem />
						)}
					</InputField>
				)
			case 'multiselect':
				return (
					<MultipleSelect
						id={id}
						helperText={helperText}
						defaultValue={fieldHasDefaultValues(tag, id, false, options, true) || default_value}
						name={id}
						label={label}
						options={options}
					/>
				)
			case 'radio':
				return (
					<RadioGroup
						id={id}
						helperText={helperText}
						defaultValue={fieldHasDefaultValues(tag, id, false, options) || default_value}
						name={id}
						label={label}
						options={options}
					/>
				)
			case 'checkbox':
				return (
					<CheckboxList
						id={id}
						helperText={helperText}
						defaultValue={fieldHasDefaultValues(tag, id, false, options, true) || default_value}
						name={id}
						label={label}
						options={options}
					/>
				)
			case 'date':
				return (
					<InputDate
						id={id}
						helperText={helperText}
						defaultValue={fieldHasDefaultValues(tag, id, false) || default_value}
						name={id}
						label={label}
					/>
				)
			case 'time':
				return (
					<InputTime
						id={id}
						helperText={helperText}
						defaultValue={fieldHasDefaultValues(tag, id, false) || default_value}
						name={id}
						label={label}
					/>
				)
			case 'datetime':
				return (
					<InputDateTime
						id={id}
						helperText={helperText}
						defaultValue={fieldHasDefaultValues(tag, id, false) || default_value}
						name={id}
						label={label}
					/>
				)
			case 'physicalexam':
				return (
					<PhysicalExam
						id={id}
						helperText={helperText}
						defaultAnswers={defaultAnswers}
						isPreview={isPreview}
					/>
				)
			case 'simple_date':
				return (
					<InputSimpleDate
						id={id}
						helperText={helperText}
						defaultValue={fieldHasDefaultValues(tag, id, false) || default_value}
						name={id}
						label={label}
					/>
				)
			default:
				return null
		}
	}, [type, id, label, readOnly, mask, options, default_value, height, weight])

	return fieldMemoized
}

export default CustomField
